import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import Categories from 'utils/categories';

import EditIcon from '@material-ui/icons/Edit';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';

import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import EventIcon from '@material-ui/icons/Event';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { bikeDetailActions } from 'screens/BikeDetail/reducer';
import { appActions } from 'commons/reducer';
import {
  composedComponent,
  dateToString,
  formatNumberToCLP,
  validateEmail,
  formatNumberToCLPSeparator,
} from 'utils/functions';
import BikeDetailImage from 'commons/components/BikeDetailImage';
import ModalPhotos from 'commons/components/ModalPhotos/ModalPhotos';
import ReportPublishDialog from 'commons/components/ReportPublishDialog/ReportPublishDialog';
import saga from 'screens/BikeDetail/saga';
import { useLocation, useParams } from 'react-router-dom';
import SendEmailDialog from 'commons/components/SendEmailDialog/SendEmailDialog';
import Carousel from 'react-material-ui-carousel';
import FileModal from 'commons/components/FileModal';

import CustomSelectField from 'commons/components/CustomAutoComplete';
import logoBikfyPay from 'commons/assets/bikfypay-logo.png';
import PaymentMethodCard from 'commons/components/PaymentMethodCard';
import SalePrice from 'commons/components/SalePrice';
import GeneralAlert from 'screens/Home/components/GeneralAlert/GeneralAlert';
import { WindowScroller } from 'react-virtualized';
import { PATHS } from 'utils/paths';
import DeletePublicationModal from './components/DeletePublicationModal/deletePublicationModal';
import ClickTooltip from '../../commons/components/ClickTooltip';
import OptionButton from './components/OptionButton';
import CustomTabs from './components/CustomTabs';
import styles from './styles';
import PublishBikeForm from './components/PublishBikeForm';
import ServicesIcons from './components/ServicesIcons';
import RememberTransferDialog from './components/RememberTransferDialog';

// EventIcon se ha ocultado a petición del cliente, se debe quitar comentario si se desea volver a mostrar
export const BikeDetail = (props) => {
  const {
    actions,
    objects,
    publishBikeForm,
    user,
    classes,
    history,
    bikeDetail: bikeDetailState,
    bikesMarkets,
    success: appSuccess,
    comunes,
  } = props;

  const loggedUser = user?.user;
  const isStoreUser = user?.user?.type === 'store';

  const {
    screenMode,
    redirectPublish,
    redirectDelete,
    modals,
    formSellerEmailValues,
    loaders,
    chargedForm,
    persistEditModeScreen,
    lastTransferId,
    deletePublicationReasonId,
  } = bikeDetailState;

  const { isLoading } = loaders;

  const {
    getPublishedBike,
    setScreenMode,
    resetControls,
    setSuccess,
    getCommissions,
    setError,
    savePublishFormValues,
    setPublishBikeFormValues,
    deletePublication,
    setOpenReportModal,
    reportPublish,
    toggleSetSellerEmailDialog,
    setErrorsSellerEmail,
    setSellerEmail,
    changeDataFile,
    openFileModal,
    clearPublishForm,
    setPersistEditModeScreen,
    setRedirect,
    setLastTransferId,
    setRememberTransferDialog,
    setDeletePublicationReason,
    clearDeletePublicationReason,
  } = actions;

  const { transferId } = useParams();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const [photos, setPhotos] = useState([]);
  const [openModalPhotos, setOpenModalPhotos] = useState(false);
  const [maintenanceId, setMaintenanceId] = useState(0);
  const [formValues, setFormValues] = useState({
    ...publishBikeForm,
  });
  const [salePrices, setSalePrices] = useState({
    creditCard: 0,
    bankTransfer: 0,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const goToEditBike = ({ transferId }) => history.push(`/bike/${transferId}`);

  const handleRedirect = (url) => history.push(url);

  // const handleReplace = (url) => history.replace(url);

  const handleSavePublishFormValues = () => {
    const reducedPublishBikeForm = Object.keys(publishBikeForm).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          value: bikesMarket[key],
          isValid: true,
          helperText: '',
        },
      }),
      {},
    );

    savePublishFormValues({ publishBikeForm: reducedPublishBikeForm });
  };

  const transfer = objects?.transfers[transferId];
  const bikesMarket = transfer?.bikesMarket;
  const buyer = transfer?.buyer;
  const bike = transfer?.bike;

  const brand = bike?.brand;
  const model = bike?.model;
  const size = bike?.size;
  const rim = bike?.rim;
  const category = bike?.category;
  const maintenances = bike?.maintenances;
  const fullBikeInformation = objects?.bikes[transfer?.bike?.id];
  const isBicycle = fullBikeInformation?.isBicycle;
  const validUserAccessed = (loggedUser && loggedUser?.id === transfer?.accessedBy) || loggedUser?.id === transfer?.buyerId;

  const isEditMode = screenMode === 'edit-mode';
  const isReadMode = screenMode === 'read-mode';

  const showDeletePublicationButton = validUserAccessed && isReadMode;
  const showLinkButton = isReadMode;

  const comissions = Object.values(objects?.comissions)[0];

  const totalCommission = comissions?.buyer + comissions?.seller + comissions?.transbank;

  const availableOptions = Object.values(bikesMarkets).find(
    (item) => item.transferId === Number(transferId),
  );

  const publishBike = () => {
    setPublishBikeFormValues({
      newPublishBikeFormValues: formValues,
      bikeMarketId: bikesMarket?.id,
      transferId,
    });
  };

  useEffect(() => {
    if (bike) {
      const description = `Compra ${bike.isBicycle ? 'bicicleta' : 'motocicleta'} registrada ${
        brand?.name
      } ${model?.name}. Tipo ${category?.name}. ${bike.isBicycle ? `Aro ${rim?.name}.` : ''} Año ${
        bike.year
      }. Precio $${formatNumberToCLPSeparator(bikesMarket?.price)} . ✓ ${
        bikesMarket?.creditCardWithFees ? 'Paga con hasta 9 cuotas sin interés' : 'Paga'
      }${bikesMarket?.creditCard ? ' con BIKFYPAY' : ''}.`;
      window.document.title = `${brand.name} ${model.name} ${
        bike.isBicycle ? `| Aro ${rim?.name}` : ''
      } | BIKFY`;
      // @ts-ignore
      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute('content', description);
      }
      // @ts-ignore
      let ogImageMetaTag = document.querySelector('meta[property="og:image"]');
      if (!ogImageMetaTag) {
        // @ts-ignore
        ogImageMetaTag = document.createElement('meta');
        ogImageMetaTag.setAttribute('property', 'og:image');
      }
      ogImageMetaTag.setAttribute(
        'content',
        'https://www.bikfy.com/static/media/logo-bikfy-main.b9ce3015.svg',
      );
      if (bike?.documents && bike.documents.length > 0) {
        ogImageMetaTag.setAttribute('content', bike.documents[0].file.url);
      }
      // @ts-ignore
      let ogTitleMetaTag = document.querySelector('meta[property="og:title"]');
      if (!ogTitleMetaTag) {
        // @ts-ignore
        ogTitleMetaTag = document.createElement('meta');
        ogTitleMetaTag.setAttribute('property', 'og:title');
      }
      ogTitleMetaTag.setAttribute(
        'content',
        `${brand.name} ${model.name} ${bike.isBicycle ? `| Aro ${rim?.name}` : ''} | BIKFY`,
      );

      // @ts-ignore
      let ogDescriptionMetaTag = document.querySelector('meta[property="og:description"]');
      if (!ogDescriptionMetaTag) {
        // @ts-ignore
        ogDescriptionMetaTag = document.createElement('meta');
        ogDescriptionMetaTag.setAttribute('property', 'og:description');
      }
      ogDescriptionMetaTag.setAttribute('content', description);
      // @ts-ignore
      let ogUrlMetaTag = document.querySelector('meta[property="og:url"]');
      if (!ogUrlMetaTag) {
        // @ts-ignore
        ogUrlMetaTag = document.createElement('meta');
        ogUrlMetaTag.setAttribute('property', 'og:url');
      }
      ogUrlMetaTag.setAttribute('content', window.location.href);

      // @ts-ignore
      const head = document.querySelector('head');
      if (head) {
        head.appendChild(ogImageMetaTag);
        head.appendChild(ogTitleMetaTag);
        head.appendChild(ogDescriptionMetaTag);
        head.appendChild(ogUrlMetaTag);
      }
    }
    return () => {
      // @ts-ignore
      window.document.title = 'Registro y compraventa de bicicletas y motos usadas | BIKFY';
      // @ts-ignore
      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute(
          'content',
          'Registra tu bicicleta y moto gratis para acceder a vender, comprar, pagar y contratar diversos servicios, todo en una plataforma segura.',
        );
      }
      const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
      if (ogImageMetaTag) {
        ogImageMetaTag.setAttribute(
          'content',
          'https://www.bikfy.com/static/media/logo-bikfy-main.b9ce3015.svg',
        );
      }
      const ogTitleMetaTag = document.querySelector('meta[property="og:title"]');
      if (ogTitleMetaTag) {
        ogTitleMetaTag.setAttribute(
          'content',
          'Registro y compraventa de bicicletas y motos usadas | BIKFY',
        );
      }
      const ogDescriptionMetaTag = document.querySelector('meta[property="og:description"]');
      if (ogDescriptionMetaTag) {
        ogDescriptionMetaTag.setAttribute(
          'content',
          'Registra tu bicicleta y moto gratis para acceder a vender, comprar, pagar y contratar diversos servicios, todo en una plataforma segura.',
        );
      }
      const ogUrlMetaTag = document.querySelector('meta[property="og:url"]');
      if (ogUrlMetaTag) {
        ogUrlMetaTag.setAttribute('content', 'https://www.bikfy.com/');
      }
    };
  }, [bike]);

  useEffect(() => {
    getPublishedBike({ transferId, newPublication: params.get('new-publication') });
    setScreenMode({ screenMode: 'read-mode' });
    return () => {
      setLastTransferId({ transferId });
    };
  }, []);

  useEffect(() => {
    if (bike) {
      const productId = `https://www.bikfy.com/product/${transferId}`;
      // const currentUrl = `${window.location.origin}${location.pathname}`;
      let currentUrl = window.location.href;

      // Remove query parameters by splitting the URL at the "?" and taking the first part
      currentUrl = currentUrl.split('?')[0];

      // Decode the URL to remove any encoded characters
      let decodedUrl = decodeURIComponent(currentUrl);

      // Ensure the URL is 100 characters or less
      if (decodedUrl.length > 100) {
        decodedUrl = decodedUrl.slice(0, 100);
      }
      const description = `Compra ${bike.isBicycle ? 'bicicleta' : 'motocicleta'} registrada ${brand?.name} ${model?.name}. Tipo ${category?.name}.`;

      // Set structured data with the ID
      const structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        '@id': decodedUrl,
        id: decodedUrl,
        name: `${brand?.name} ${model?.name}`,
        description,
        image: bike.documents[0]?.file?.url,
        offers: {
          '@type': 'Offer',
          url: decodedUrl, // Product URL
          priceCurrency: 'CLP', // Currency in ISO 4217 format
          price: bikesMarket?.price, // Price of the product
          availability: 'https://schema.org/InStock', // Stock status
          itemCondition: 'https://schema.org/UsedCondition', // Product condition
        },
      };

      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.innerHTML = JSON.stringify(structuredData);
      document.head.appendChild(script);

      return () => {
        // Clean up the script tag
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      };
    }
  }, [bike, transferId, brand, model, category, bikesMarket]);

  useEffect(() => {
    if (!isLoading) {
      const currentTransferIsEqualToLast = transferId === lastTransferId;
      if (bikesMarket) {
        if (!currentTransferIsEqualToLast && validUserAccessed) {
          setScreenMode({ screenMode: 'read-mode' });
          handleSavePublishFormValues();
        } else if (persistEditModeScreen && validUserAccessed) {
          setScreenMode({ screenMode: 'edit-mode' });
        }
      } else if (params.get('new-publication') && validUserAccessed) {
        setScreenMode({ screenMode: 'edit-mode' });
        if (!currentTransferIsEqualToLast) {
          clearPublishForm();
        }
      }
    }
  }, [isLoading, transferId, validUserAccessed, persistEditModeScreen]);

  useEffect(() => {
    if (redirectPublish) {
      const bikeString = isBicycle
        ? `${encodeURIComponent(brand.name)}-${encodeURIComponent(model.name)}-${encodeURIComponent(category.name)}-${encodeURIComponent(size.name)}-${encodeURIComponent(rim.name)}`
        : `${encodeURIComponent(brand.name)}-${encodeURIComponent(model.name)}-${encodeURIComponent(category.name)}-${encodeURIComponent(bike?.year)}`;

      let pathname = PATHS.MARKETPLACE_DETAIL.replace(':transferId', transferId);
      pathname = pathname.replace(':bikeString', bikeString);
      pathname = pathname.replace(':vehicleType', transfer?.bike?.isBicycle ? 'bicicleta' : 'motocicleta');

      // handleRedirect(`/publish-bike/${transferId}`);
      handleRedirect(pathname);
      setRedirect({ redirect: false });
    }
    if (redirectDelete) {
      handleRedirect('/home');
      setRedirect({ redirect: false });
    }
    return () => {
      setRedirect({ redirect: false });
    };
  }, [redirectPublish, redirectDelete]);

  useEffect(() => {
    const formData = params.get('new-publication')
      ? {
        ...publishBikeForm,
        comuneId: { ...publishBikeForm.comuneId, value: loggedUser?.comuneId },
      }
      : { ...publishBikeForm };

    setFormValues(formData);
  }, [publishBikeForm]);

  const updateSalePrices = (salePrice = formValues.price.value) => {
    let finalPrice = 0;

    if (formValues.creditCard.value) {
      finalPrice = calculatePrice({
        bikePrice: salePrice,
        commissionToApply: comissions?.creditCard,
      });
    }

    if (formValues?.creditCardWithFees?.value) {
      finalPrice = calculatePrice({
        bikePrice: salePrice,
        commissionToApply: comissions?.creditCardWithFees,
      });
    }

    setSalePrices((prevState) => ({
      ...prevState,
      creditCard: finalPrice,
      bankTransfer: salePrice ? salePrice - comissions?.bankTransfer : 0,
    }));
  };

  useEffect(() => {
    updateSalePrices();
  }, [isEditMode]);

  const validValue = useCallback((value) => `${value ?? ''}`, []);

  const handleClickWhatsAppButton = () => {
    const { phone } = transfer?.buyer;

    if (!phone) return;

    const replacedBuyerPhone = phone.replace(/[+]/, '');

    const customText = 'Hola, vi tu Bike publicada en BIKFY, ¿sigue disponible?';

    window.open(`https://wa.me/${replacedBuyerPhone}?text=${customText}`, '_blank');
  };

  const handleClickSendEmailButton = (event) => {
    const { id: bikeId } = bike;
    toggleSetSellerEmailDialog({ open: true, bikeId, transferId });
  };

  // here
  const handleClickButton = () => {
    if (isReadMode) {
      setScreenMode({ screenMode: 'edit-mode' });
      handleSavePublishFormValues();
    } else {
      setPersistEditModeScreen({ persistEditModeScreen: true });
      savePublishFormValues({ publishBikeForm: formValues });
      goToEditBike({ transferId });
    }
  };
  const handleDeletePublication = () => {
    deletePublication({
      bikeMarketId: bikesMarket?.id,
      deletedReasonId: Number(deletePublicationReasonId),
      history,
    });
  };
  const handleOpenOrCloseDeleteModal = (event) => {
    setOpenDeleteModal(!openDeleteModal);
    clearDeletePublicationReason();
  };

  const handleClickShareButton = () => {
    const { location } = window;
    navigator.clipboard.writeText(location.href).then(() => {
      setSuccess('url copiada');
    });
  };

  const calculatePrice = ({ bikePrice = formValues.price.value, commissionToApply }) => {
    const discount = (bikePrice * commissionToApply) / 100;

    return bikePrice - discount;
  };

  const handleChange = ({ target }) => {
    const {
      value, name, checked, type,
    } = target;

    let newValue = '';

    if (type === 'checkbox') {
      newValue = checked;
    } else {
      newValue = value;
    }

    if (name === 'creditCard') {
      const finalPrice = checked
        ? calculatePrice({ commissionToApply: comissions?.creditCard })
        : 0;
      setSalePrices((prevState) => ({ ...prevState, creditCard: finalPrice }));

      if (!checked) {
        setFormValues((prevState) => ({
          ...prevState,
          creditCardWithFees: {
            ...prevState.creditCardWithFees,
            value: newValue,
          },
        }));
      }
    }

    if (name === 'creditCardWithFees') {
      const finalPrice = checked
        ? calculatePrice({ commissionToApply: comissions?.creditCardWithFees })
        : calculatePrice({ commissionToApply: comissions?.creditCard });

      setSalePrices((prevState) => ({ ...prevState, creditCard: finalPrice }));
    }

    if (name === 'price') {
      let creditCardPrice = 0;

      if (formValues.creditCardWithFees.value) {
        creditCardPrice = calculatePrice({
          bikePrice: value,
          commissionToApply: comissions?.creditCardWithFees,
        });
      } else if (formValues.creditCard.value) {
        creditCardPrice = calculatePrice({
          bikePrice: value,
          commissionToApply: comissions?.creditCard,
        });
      }

      setSalePrices((prevState) => ({
        ...prevState,
        creditCard: creditCardPrice,
        bankTransfer: value ? value - comissions?.bankTransfer : 0,
      }));
    }

    setFormValues((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: newValue,
      },
    }));
  };

  const handleBlur = ({ target }) => {
    const price = Number(target.value.replace(/[$|.]/g, ''));
    updateSalePrices(price);
  };

  const handleSetSellerEmail = ({ formSellerEmailValues }) => {
    const validEmail = validateEmail(formSellerEmailValues.buyerEmail.value);

    if (!validEmail) {
      return setErrorsSellerEmail({
        formSellerEmailValues: {
          ...formSellerEmailValues,
          buyerEmail: {
            ...formSellerEmailValues.buyerEmail,
            isValid: false,
            helperText: 'El correo es invalido',
          },
        },
      });
    }

    setSellerEmail({ formSellerEmailValues });
  };

  const publishedBikeValue = useMemo(() => {
    if (formValues.withBikfyPay?.value) {
      const numberBikeValue = Number(formValues.price?.value);

      const bikfyCommission = (numberBikeValue * (comissions?.buyer + comissions?.seller + comissions?.transbank)) / 100;

      const finalValue = numberBikeValue - bikfyCommission;

      return finalValue;
    }

    return formValues.price?.value;
  }, [formValues.price?.value, formValues.withBikfyPay?.value, comissions]);

  const handleSetFormValues = () => {
    const validPhotos = (transfer?.bike?.documents ?? []).filter((d) => ['right_side', 'front_side', 'left_side'].includes(d.type));

    if (validPhotos.length < 3) {
      return setError({
        errorMsg:
          'Para publicar una Bike, debes ingresar todas las fotos: Lateral Izquierdo, Frontal y Lateral derecho',
      });
    }
    if (Number(formValues.price.value) <= 0) {
      return setError({
        errorMsg: 'Debes ingresar un precio valido para publicar la Bike',
      });
    }
    localStorage.removeItem('publishBikeForm');
    setRememberTransferDialog(true);
  };

  const handleclosePhotos = () => {
    setOpenModalPhotos(false);
  };

  const handleOpenPhotos = (documents) => {
    setOpenModalPhotos(true);

    const docs = documents.filter((doc) => ['left_side', 'front_side', 'right_side', 'extra'].includes(doc.type));
    setPhotos(docs);
  };

  const doNothing = (e) => e.stopPropagation();

  const handleSetOpenReportModal = () => {
    const open = !modals.report.open;

    const bikeMarketId = bikesMarket?.id;

    setOpenReportModal({ open, bikeMarketId: open ? bikeMarketId : 0 });
  };

  const handlePublishReport = ({ reportReasonId }) => {
    reportPublish({ reportReasonId });
  };

  const handleClickTransferBike = (event) => {
    history.push(`/bike/${transferId}/transfer`);
  };

  const handleGetFile = (id) => {
    const { name, url } = props.objects.maintenances[id].file;
    setMaintenanceId(id);
    let extension = name.split('.');
    extension = extension[extension.length - 1];

    changeDataFile({
      extension,
      url,
      name,
    });
    openFileModal();
  };

  const handleClickPublishButton = () => handleSetFormValues();

  const descriptionComponent = isEditMode && !isLoading ? (
    <PublishBikeForm formValues={formValues} handleChange={handleChange} />
  ) : (
    <Typography style={{ whiteSpace: 'pre-wrap' }} itemProp="description">
      {validValue(bikesMarket?.description)}
    </Typography>
  );

  return (
    <Grid container>
      {transfer && (
        <>
          {bikesMarket && validUserAccessed && transfer?.bike?.isBicycle && (
            <GeneralAlert alertType="publishOnSocialMedia" severity="info" />
          )}
          <Grid item xs={12}>
            <Paper>
              <article itemScope itemType="http://schema.org/Product">
                <Grid container spacing={2} style={{ padding: '7px' }}>
                  {/* COLUMNA 1 */}
                  <Grid item md={4} xs={12} className={`${classes.marginBottom10}`}>
                    <Carousel interval={10000}>
                      {transfer?.bike?.documents?.length > 0 ? (
                        (transfer?.bike?.documents).map((doc) => (
                          <Box key={doc.id} style={{ height: '75vh' }}>
                            <BikeDetailImage
                              url={doc?.file?.url}
                              openModal={() => handleOpenPhotos(transfer?.bike?.documents)}
                            />
                          </Box>
                        ))
                      ) : !isLoading ? (
                        <Box display="flex" className={classes.root} onClick={handleClickButton}>
                          <img
                            src={
                              Categories[
                                transfer?.bike?.category?.name
                                  || (transfer?.bike?.isBicycle ? 'Downhill' : 'Enduro')
                              ]
                            }
                            alt="Foto"
                            className={classes.blur}
                          />
                          <Box className={classes.eye}>
                            <AddPhotoAlternateIcon
                              className={classes.icon}
                              color="primary"
                              style={{ fontSize: 60 }}
                            />
                            <Button
                              variant="text"
                              size="small"
                              color="primary"
                              className={classes.btn}
                            >
                              SUBIR FOTOS
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <Box display="flex" className={classes.root}>
                          <Box className={classes.loadingBox}>
                            <CircularProgress className={classes.btn} />
                          </Box>
                        </Box>
                      )}
                    </Carousel>
                  </Grid>

                  {/* COLUMNA 2 */}
                  <Grid item xs={12} md={isEditMode ? 4 : 8} className={classes.details}>
                    <Grid
                      container
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Grid
                        item
                        xs={9}
                        style={{ display: 'flex', flexDirection: 'column' }}
                        alignItems="center"
                      >
                        <ServicesIcons
                          showBikfyPayIcon={
                            isReadMode ? availableOptions?.creditCard : formValues.creditCard.value
                          }
                          creditCardWithFees={
                            isReadMode
                              ? availableOptions?.creditCardWithFees
                              : formValues.creditCardWithFees.value
                          }
                          showDeliveryIcon={
                            isReadMode
                              ? availableOptions?.withDelivery
                              : formValues.withDelivery.value
                          }
                          withInvoice={transfer?.withInvoice}
                          accessedBy={transfer?.accessedBy}
                        />
                      </Grid>
                      <Grid
                        className="centeredContent"
                        container
                        item
                        xs={12}
                        md={3}
                        alignItems="flex-start"
                        justifyContent="flex-end"
                      >
                        {showLinkButton && (
                          <Tooltip title="Compartir">
                            <IconButton onClick={handleClickShareButton}>
                              <LinkIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {validUserAccessed && (
                          <Tooltip title={isEditMode ? 'Editar Bike' : 'Editar publicación'}>
                            <IconButton onClick={handleClickButton}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {showDeletePublicationButton && (
                          <Tooltip title="Eliminar" onClick={handleOpenOrCloseDeleteModal}>
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid item xs={12} className="centeredContent">
                          <Typography
                            variant="h1"
                            itemProp="name"
                            style={{
                              fontWeight: '700',
                              fontSize: '2.125rem',
                              fontFamily: 'Mark Pro',
                            }}
                          >
                            <Box fontWeight="fontWeightBold">
                              {`${validValue(brand?.name)} ${validValue(model?.name)}`}
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className="centeredContent">
                          <Typography variant="h6">
                            <Box fontWeight={500}>
                              {isBicycle && (
                                <span
                                  itemProp="additionalProperty"
                                  content={`Talla ${validValue(size?.name)}`}
                                >
                                  {`Talla ${validValue(size?.name)}`}
                                  {' '}
                                  |
                                </span>
                              )}
                              <span itemProp="offers" itemScope itemType="http://schema.org/Offer">
                                <meta itemProp="price" content={bikesMarket?.price} />
                                <meta itemProp="priceCurrency" content="CLP" />
                                <link itemProp="availability" href="http://schema.org/InStock" />
                              </span>
                              <span
                                itemProp="additionalProperty"
                                content={`Año ${validValue(bike?.year)}`}
                              >
                                {` Año ${validValue(bike?.year)}`}
                              </span>
                              {isBicycle ? (
                                <span
                                  itemProp="additionalProperty"
                                  content={`Aro ${validValue(rim?.name)}`}
                                >
                                  {` | Aro ${validValue(rim?.name)}`}
                                </span>
                              ) : (
                                fullBikeInformation?.motorbike?.engineCapacity?.capacity && (
                                  <span
                                    itemProp="additionalProperty"
                                    content={`Cilindraje ${validValue(
                                      fullBikeInformation?.motorbike?.engineCapacity?.capacity,
                                    )}`}
                                  >
                                    {` | Cilindraje ${validValue(
                                      fullBikeInformation?.motorbike?.engineCapacity?.capacity,
                                    )}`}
                                  </span>
                                )
                              )}
                            </Box>
                          </Typography>
                        </Grid>
                      </Grid>
                      {isReadMode && (
                        <Grid item xs={12} className="centeredContent">
                          <SalePrice
                            handleChange={handleChange}
                            isEditMode={isEditMode}
                            priceToShow={formatNumberToCLP(Number(validValue(bikesMarket?.price)))}
                            value={formValues.price.value}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        {/* <LocationOnOutlinedIcon /> */}
                        <Grid item xs={12}>
                          {isEditMode ? (
                            <>
                              <LocationOnOutlinedIcon />
                              <CustomSelectField
                                options={Object.values(comunes)?.map((comune) => ({
                                  label: comune?.name,
                                  value: comune?.id,
                                }))}
                                name="comuneId"
                                size="small"
                                onChange={handleChange}
                                value={formValues.comuneId.value}
                              />
                            </>
                          ) : (
                            <Grid className="centeredContent" container xs={12}>
                              <LocationOnOutlinedIcon />
                              <Typography itemProp="availableAtOrFrom">
                                {`${
                                  objects?.comunes[bikesMarket?.comuneId]?.name ?? buyer?.commune
                                }`}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid className="centeredContent" container item xs={12}>
                        {/* <EventIcon />
                        <Typography itemProp="datePosted">{`Publicado el ${dateToString(bikesMarket?.createdAt)}`}</Typography> */}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className={classes.content}
                      style={{
                        marginTop: '20px',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          marginTop: '20px',
                        }}
                      >
                        {descriptionComponent}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      justifyContent={isMobileScreen ? 'center' : 'flex-end'}
                      className={classes.controls}
                      style={{ marginTop: '20px' }}
                    >
                      {!isEditMode && !validUserAccessed && (
                        <>
                          {bikesMarket?.showContact && (
                            <Tooltip
                              className={classes.cursorPointer}
                              title={
                                !user ? 'Para contactar debes iniciar sesión' : 'Enviar WhatsApp'
                              }
                            >
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  id="btn-send-whatsapp"
                                  disabled={!user}
                                  onClick={handleClickWhatsAppButton}
                                >
                                  <WhatsAppIcon />
                                  {!isMobileScreen && 'Enviar Whatsapp'}
                                </Button>
                              </Grid>
                            </Tooltip>
                          )}
                          <Tooltip
                            title={!user ? 'Para contactar debes iniciar sesión' : 'Enviar correo'}
                            placement="top"
                            className={classes.cursorPointer}
                          >
                            <Grid item>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={!user}
                                onClick={(e) => (user ? handleClickSendEmailButton(e) : doNothing(e))}
                                id="btn-send-email"
                              >
                                <MailOutlineIcon />
                                {!isMobileScreen && 'Enviar Correo'}
                              </Button>
                            </Grid>
                          </Tooltip>
                          <Grid item>
                            <OptionButton
                              handleSetOpenReportModal={handleSetOpenReportModal}
                              user={user}
                            />
                          </Grid>
                        </>
                      )}

                      {!isEditMode && validUserAccessed && !isStoreUser && (
                        <>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClickTransferBike}
                            style={isMobileScreen ? { marginTop: '30px' } : {}}
                          >
                            Transferir
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  {/* COLUMNA 3 */}
                  {isEditMode && !isLoading && (
                    <Grid item md={4} xs={12}>
                      <Grid item xs={12} style={{ marginBottom: 15 }}>
                        <Card>
                          <CardContent>
                            <Box display="flex" flexDirection="column" maxWidth={250}>
                              <Box
                                display="inherit"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Box display="inherit" alignItems="inherit">
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        checked={formValues.withDelivery.value}
                                        onChange={handleChange}
                                        name="withDelivery"
                                      />
                                    )}
                                    label={(
                                      <Typography className={`${classes.fWeight700}`}>
                                        ¿Haces envíos?
                                      </Typography>
                                    )}
                                  />
                                </Box>
                                <ClickTooltip
                                  title="Ofrezco el servicio de envío a mis potenciales compradores y libero a BIKFY de cualquier responsabilidad en la entrega de mi bicicleta."
                                  component={ErrorOutlineIcon}
                                />
                              </Box>

                              <Box
                                display="inherit"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Box display="inherit" alignItems="inherit">
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        checked={formValues.showContact.value}
                                        onChange={handleChange}
                                        name="showContact"
                                      />
                                    )}
                                    label={(
                                      <Typography className={`${classes.fWeight700}`}>
                                        Habilitar Whatsapp
                                      </Typography>
                                    )}
                                  />
                                </Box>
                                <ClickTooltip
                                  title="Deseo que los potenciales compradores me contacten a mi Whatsapp."
                                  component={ErrorOutlineIcon}
                                />
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={12} style={{ marginBottom: 15 }}>
                        <PaymentMethodCard
                          bankTransferAvailable={formValues?.bankTransfer?.value}
                          bankTransferTotal={Number(salePrices.bankTransfer)}
                          creditCardAvailable={formValues?.creditCard?.value}
                          creditCardTotal={Number(salePrices.creditCard)}
                          creditCardWithFeesAvailable={formValues?.creditCardWithFees?.value}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          isEditMode={isEditMode}
                          logo={logoBikfyPay}
                          price={formValues.price.value}
                          priceToShow={formatNumberToCLP(Number(validValue(bikesMarket?.price)))}
                          isStoreUser={isStoreUser}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={formValues.authorizedPublication.value}
                              onChange={handleChange}
                              name="authorizedPublication"
                              className={classes.authorizedPublicationCheck}
                            />
                          )}
                          label={(
                            <>
                              <Typography className={classes.authorizedPublicationLabel}>
                                Autorizo a BIKFY a publicar mi Bike en otros canales.
                                <ClickTooltip
                                  title="BIKFY podrá publicar tu Bike en distintos canales tales como Facebook, Instagram, Whatsapp, Emailing, entre otros."
                                  component={ErrorOutlineIcon}
                                />
                              </Typography>
                            </>
                          )}
                        />
                      </Grid>

                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: '20px' }}
                        onClick={handleClickPublishButton}
                      >
                        Publicar
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </article>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <CustomTabs
                buyer={transfer?.buyer}
                bikfyReportBikeInfo={{
                  brand: brand?.name,
                  rim: rim?.name,
                  size: size?.name,
                  color: bike?.color,
                  serial: bike?.serial,
                  year: bike?.year, // no esta
                  version: bike?.version,
                  model: model?.name,
                  isBicycle,
                  category: category?.name,
                  disabled: bike?.disabled,
                  reported: bike?.reported,
                  userType: bike?.userType,
                  bikeType: bike?.bikeType,
                  invoiceIssuedIn: bike?.invoiceIssuedIn,
                  numberOfOwners: bike?.numberOfOwners,
                  motorbike: fullBikeInformation?.motorbike,
                }}
                user={user}
                maintenances={maintenances}
                handleGetFile={handleGetFile}
              />
            </Paper>
          </Grid>

          <ModalPhotos open={openModalPhotos} handleClose={handleclosePhotos} photos={photos} />
          <RememberTransferDialog
            open={modals.rememberTransfer.open}
            onSubmit={publishBike}
            setOpen={setRememberTransferDialog}
          />
          <FileModal
            open={props.openModal}
            clear={props.actions.closeFileModal}
            fileData={props.dataFile}
            maintenance={props.objects.maintenances[maintenanceId]}
          />

          <ReportPublishDialog
            open={modals?.report?.open}
            reportReasons={Object.values(objects?.reportReasons)}
            handlePublishReport={handlePublishReport}
            handleToggleReportPublishModal={handleSetOpenReportModal}
          />
          <SendEmailDialog
            formSellerEmailValues={formSellerEmailValues}
            open={modals.setSellerEmail.open}
            toggleSetSellerEmailDialog={toggleSetSellerEmailDialog}
            handleSetSellerEmail={handleSetSellerEmail}
            user={loggedUser}
          />
          <DeletePublicationModal
            isOpen={openDeleteModal}
            handleOpenOrCloseDeleteModal={handleOpenOrCloseDeleteModal}
            deletedPublicationReasons={Object.values(objects?.deletedPublicationReasons)}
            setDeletePublicationReason={setDeletePublicationReason}
            deletePublicationReasonId={deletePublicationReasonId}
            handleDeletePublication={handleDeletePublication}
            classes={classes}
          />
        </>
      )}
    </Grid>
  );
};

export default composedComponent(BikeDetail, saga, {
  states: [
    'bikeDetail.formControls',
    'bikeDetail.bikeSuccess',
    'bikeDetail.publishBikeForm',
    'bikeDetail.loading',
    'bikeDetail.openModal',
    'bikeDetail.dataFile',
    'bikeDetail',
    'app.objects',
    'app.objects.buyer',
    'app.objects.bike',
    'app.objects.bikesMarkets',
    'app.objects.brands',
    'app.objects.maintenances',
    'app.objects.comunes',
    'app.order',
    'app.user',
    'app.success',
  ],
  actions: [bikeDetailActions, appActions],
  saga: 'bikeDetailSaga',
  middlewares: [withStyles(styles)],
});
