import React from 'react';
import {
  Button, CircularProgress, Grid, TextField,
} from '@material-ui/core';
import CustomSelect from 'commons/components/CustomSelect';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import { formatTaxNumber, serialInput } from 'utils/functions';
import CustomInputFile from 'commons/components/CustomInputFile';
import CustomInputNumber from 'commons/components/CustomInputNumber';

const priceTable = [
  { DESDE: 0, HASTA: 300000, BCI_ACTUAL: [2994, 4427] },
  { DESDE: 300001, HASTA: 500000, BCI_ACTUAL: [2994, 4427] },
  { DESDE: 500001, HASTA: 700000, BCI_ACTUAL: [4937, 6625] },
  { DESDE: 700001, HASTA: 1000000, BCI_ACTUAL: [4937, 6625] },
  { DESDE: 1000001, HASTA: 1500000, BCI_ACTUAL: [7039, 8536] },
  { DESDE: 1500001, HASTA: 2000000, BCI_ACTUAL: [9874, 11466] },
  { DESDE: 2000001, HASTA: 2500000, BCI_ACTUAL: [12899, 13950] },
  { DESDE: 2500001, HASTA: 3000000, BCI_ACTUAL: [15479, 17104] },
  { DESDE: 3000001, HASTA: 3500000, BCI_ACTUAL: [18027, 19684] },
  { DESDE: 3500001, HASTA: 4000000, BCI_ACTUAL: [20607, 22263] },
  { DESDE: 4000001, HASTA: 4500000, BCI_ACTUAL: [23187, 24843] },
  { DESDE: 4500001, HASTA: 500000000, BCI_ACTUAL: [24716, 27423] },
];

function getBCIActual(amount, type) {
  for (let i = 0; i < priceTable.length; i++) {
    if (amount >= priceTable[i].DESDE && amount <= priceTable[i].HASTA) {
      // return priceTable[i].BCI_ACTUAL[type];
      return new Intl.NumberFormat('es-CL').format(priceTable[i].BCI_ACTUAL[type]);
    }
  }
  return null; // If no match is found
}

const BikeForm = ({
  controls = {},
  errors = {},
  onControlChange,
  onSubmit = (e) => e.preventDefault(),
  brands = [],
  motorbikeBrands = [],
  vehicleTypes = [],
  loading,
  isBicycleSelected = false,
}) => {
  const inputs = [
    {
      name: 'vehicleTypeId',
      label: 'Tipo de vehículo',
      type: 'select',
      options: vehicleTypes,
      visible: true,
      sm: 10,
    },
    {
      name: 'email',
      label: 'E-Mail cliente',
      type: 'text',
      visible: true,
    },
    {
      name: 'taxNumber',
      label: 'RUT Cliente',
      type: 'text',
      visible: true,
      getValue: formatTaxNumber,
    },
    {
      name: 'amount',
      label: 'Valor en factura o boleta',
      type: 'number',
      visible: true,
    },
    {
      name: 'invoiceNumber',
      label: 'N° de factura o boleta',
      type: 'text',
      visible: true,
    },
    {
      name: 'serial',
      label: 'Código de serie',
      visible: isBicycleSelected,
      type: 'text',
      getValue: (s) => serialInput(s),
    },
    {
      name: 'chassisSerial',
      label: 'Código de serie chasis',
      visible: !isBicycleSelected,
      type: 'text',
      getValue: (s) => serialInput(s),
    },
    {
      name: 'engineSerial',
      label: 'Código de serie motor',
      visible: !isBicycleSelected,
      type: 'text',
      getValue: (s) => serialInput(s),
    },
    {
      name: 'brandId',
      label: 'Marca',
      visible: true,
      type: 'select',
      options: isBicycleSelected ? brands : motorbikeBrands,
    },
    {
      name: 'insurance',
      label: 'Seguro seleccionado',
      visible: isBicycleSelected,
      type: 'select',
      options: [{ value: 0, label: 'Ninguno' }, { value: 1, label: `Básico ➡️ CLP ${getBCIActual(controls.amount, 0)}` }, { value: 2, label: `Full ➡️ CLP ${getBCIActual(controls.amount, 1)}` }],
    },
  ];
  console.log(brands);
  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {inputs.map((i) => (i.type === 'text' && i?.visible ? (
          <Grid key={i.name} item xs={12} sm={5} align="center">
            <TextField
              name={i.name}
              label={i.label}
              variant="outlined"
              fullWidth
              value={i.getValue ? i.getValue(controls[i.name]) : controls[i.name]}
              onChange={(e) => onControlChange(e, i.getValue)}
              error={!!errors[i.name]}
              helperText={errors[i.name] && errors[i.name][0]}
            />
          </Grid>
        ) : i.type === 'number' ? (
          <Grid key={i.name} item xs={12} sm={5} align="center">
            <CustomInputNumber
              label="Valor en factura o boleta"
              name="amount"
              onChange={onControlChange}
              value={controls[i.name]}
              error={!!errors[i.name]}
              helperText={errors[i.name] && errors[i.name][0]}
            />
          </Grid>
        ) : i.type === 'select' && i?.visible ? (
          <Grid key={i.name} item xs={12} sm={i.sm ? i.sm : 5} align="center">
            <CustomAutoComplete
              name={i.name}
              label={i.label}
              value={controls[i.name]}
              onChange={onControlChange}
              options={i.options}
              error={!!errors[i.name]}
              errors={errors[i.name]}
            />
          </Grid>
        ) : i.type === 'file' ? (
          <Grid key={i.name} item xs={12} sm={12} align="center">
            <CustomSelect
              name={i.name}
              label={i.label}
              value={controls[i.name]}
              onChange={onControlChange}
              options={i.options}
              hasError={!!errors[i.name]}
              errors={errors[i.name]}
            />
          </Grid>
        ) : null))}
        <Grid item xs={12} sm={10} align="center">
          <CustomInputFile
            title="Subir foto de la factura o boleta"
            id="invoiceFile"
            name="invoiceFile"
            documentType="documentInvoice"
            onChange={onControlChange}
            value={controls.invoiceFile}
          />
        </Grid>
        <Grid item xs={12} sm={12} align="center">
          <Button type="submit" variant="contained" color="primary">
            {loading ? <CircularProgress size={24} /> : 'Registrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default BikeForm;
