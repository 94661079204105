import React from 'react';
import {
  Grid, Typography, Button, makeStyles,
  Tooltip,
} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StorefrontIcon from '@material-ui/icons/Storefront';
import StarIcon from '@material-ui/icons/Star';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PATHS } from 'utils/paths';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    padding: theme.spacing(4),
    backgroundColor: '#f0f8ff', // Adjust the background color as needed
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const CenteredTextWithButtons = () => {
  const classes = useStyles();
  const history = useHistory();
  const handleRedirectToMarketPlace = () => {
    history.push(PATHS.MARKETPLACE);
  };

  return (
    <div className={classes.container}>
      <Typography
        variant="h5"
        component="h3"
        style={{
          fontWeight: '500',
          fontSize: '30px',
          paddingBottom: '12px',
          color: '#303E48',
        }}
      >
        {' '}

        Marketplace
      </Typography>
      <Typography
        variant="h6"
        style={{
          fontWeight: '500',
          fontSize: '18px',
          paddingBottom: '12px',
          color: '#303E48',
        }}
        gutterBottom
        component="h4"
      >
        Somos el Marketplace más seguro del mercado. Compra sabiendo la procedencia de la bicicleta y/o moto, evita fraudes y obtén la propiedad de la Bike legalmente a tu nombre.
      </Typography>
      <Grid container justifyContent="center" className={classes.buttonsContainer}>
        <Button
          variant="outlined"
          color="primary"
          style={{
            height: '60px',
            maxWidth: '350px',
            borderRadius: '30px',
          }}
          onClick={() => handleRedirectToMarketPlace()}
          className={classes.button}
          startIcon={<StorefrontIcon />}
        >
          VER MARKETPLACE
        </Button>
        <Tooltip title="Próximamente">
          <span>
            <Button
              variant="contained"
              color="primary"
              style={{
                height: '60px',
                maxWidth: '350px',
                borderRadius: '30px',
              }}
              className={classes.button}
              startIcon={<StarIcon />}
              disabled
            >
              VER SEMI-NUEVAS
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </div>
  );
};

export default CenteredTextWithButtons;
