import React, { Fragment } from 'react';

// MATERIAL
import {
  Box,
  Button,
  Card,
  IconButton,
  Typography,
  makeStyles,
  CardContent,
  Grid,
  withStyles,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EditIcon from '@material-ui/icons/Edit';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

// COMPONENTS
import { composedComponent, formatNumberToCLP } from 'utils/functions';
import Categories from 'utils/categories';
import { appActions } from 'commons/reducer';
import { PATHS } from 'utils/paths';
import { view } from 'utils/pathTypes';
import { STORE_BIKE_STATUS } from 'utils/consts';
import { Alert } from '@material-ui/lab';
import { Email } from '@material-ui/icons';
import BikeImage from '../BikeImage';

import saga from '../../saga';
import { styles } from './styles';
import OptionButton from '../OptionButton/OptionButton';
import DatePublished from '../DatePublished';
import OptionsAvailable from '../OptionsAvailable';
import { homeActions } from '../../reducer';

// DatePublished se ha ocultado a pedido del cliente, se debe quitar comentario en caso se requiera.
const validate = (value) => value || 'No especificado';

const btns = {
  sendWhatsapp: ({ callback, disabled }) => (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<WhatsAppIcon />}
      onClick={callback}
      id="btn-send-whatsapp"
      disabled={disabled}
    >
      Enviar whatsapp
    </Button>
  ),
  sendEmail: ({ callback, disabled }) => (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<MailOutlineIcon />}
      onClick={callback}
      id="btn-send-email"
      disabled={disabled}
    >
      Enviar Correo
    </Button>
  ),
  maintenance: ({
    callback, className, isXS, disabled,
  }) => (
    <Button
      variant="contained"
      onClick={callback}
      className={className}
      disabled={disabled}
      fullWidth
      startIcon={!isXS && <BuildOutlinedIcon />}
    >
      {isXS ? <BuildOutlinedIcon /> : 'Taller'}
    </Button>
  ),
  insurance: ({
    callback, className, isXS, disabled,
  }) => (
    <Button
      variant="contained"
      onClick={callback}
      className={className}
      disabled={disabled}
      fullWidth
      startIcon={!isXS && <VerifiedUserIcon />}
    >
      {isXS ? <VerifiedUserIcon /> : 'Asegurar'}
    </Button>
  ),
  publish: ({
    callback, isPublished, isXS, disabled,
  }) => (
    <Button
      variant={isPublished ? 'outlined' : 'contained'}
      color="primary"
      fullWidth
      disabled={disabled}
      onClick={callback}
      startIcon={!isXS && <>{isPublished ? <VisibilityIcon /> : <StorefrontIcon />}</>}
    >
      {isXS ? (
        <>{isPublished ? <VisibilityIcon /> : <StorefrontIcon />}</>
      ) : (
        <>{isPublished ? 'Ver publicación' : 'Publicar'}</>
      )}
    </Button>
  ),
  transfer: ({ callback, isXS, disabled }) => (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      disabled={disabled}
      onClick={callback}
      startIcon={!isXS && <SwapHorizIcon />}
    >
      {isXS ? <SwapHorizIcon /> : 'Vender/Transferir'}
    </Button>
  ),
  seeReport: ({ callback }) => (
    <Button color="primary" variant="contained" onClick={callback}>
      VER INFORME
    </Button>
  ),
};

const GeneralCard = (props) => {
  const {
    actions,
    transferId,
    categoryName,
    brandName,
    modelName,
    version,
    isReportedBike,
    setOpenPhotosModal,
    showValidBikes,
    showPendingBikes,
    showTransferedBikes,
    brandId,
    bikeSerial,
    bikeYear,
    bikeId,
    sizeName,
    onChange,
    goToBankDataInfo,
    openAlertDelete,
    handleToggleModalPaymentStatus,
    isBikfyPaid,
    userId,
    sellerId,
    goToEditPage,
    goToTransfer,
    goToInsurance,
    goToWorkshop,
    goToReport,
    goToReportBikfy,
    goToAcceptTransfer,
    listType,
    bikeMarket,
    handleToggleSetSellerEmailDialog,
    handleToggleReportPublishModal,
    transfer,
    classes,
    user,
    rimName,
    handleRedirectWithState,
    handleRedirect,
    requestAccess,
    removeStoreAccess,
    isPilotBikesPath,
    isStoreBikesPath,
    status,
    pathType,
    sortBy,
    storeHomeSortBy,
    motorbikeInformation,
  } = props;

  const motorbikeChassisSerial = motorbikeInformation?.chassisSerial;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  const currentUserIsSeller = userId === sellerId;

  const isMozaicList = listType === 'mozaic-list';
  const iconClass = listType === 'mozaic-list' ? classes.mozaicIcon : '';

  // precio card
  const priceCardClass = isMozaicList ? classes.mozaicCardPrice : classes.normalListPrice;

  const titleCardClass = isMozaicList ? '' : classes.normalListTitleCard;

  const sizeYearClass = isMozaicList ? '' : classes.sizeYearTypography;

  const iconsServiceClass = isMozaicList ? classes.iconsService : '';

  const bikeCardDescription = transfer?.bike?.isBicycle
    ? `Talla ${validate(sizeName)} | Año ${validate(bikeYear)}
    ${rimName ? `| Aro ${rimName}` : ''}`
    : `${validate(categoryName)} | Año ${validate(bikeYear)} ${
      motorbikeInformation?.engineCapacity?.capacity
        ? `| ${motorbikeInformation?.engineCapacity?.capacity}`
        : ''
    }`;

  const imgUrl = transfer?.bike?.documents?.find((d) => d.type === 'right_side')?.file.url
    || Categories[categoryName || (transfer?.bike?.isBicycle ? 'Downhill' : 'Enduro')];

  const avatar = transfer?.buyer?.avatar;

  const isDisabled = transfer?.bike?.disable || transfer?.bike?.reported;

  const isBikeDataCompleted = !!transfer?.bike?.dataCompletedAt;
  const isMotorbikeDataCompleted = motorbikeInformation
    ? !motorbikeInformation.dataComplete
    : false;
  const isBikeInsurable = !!transfer?.isInsurable;
  const isInsured = !!transfer?.isInsured;
  const isPreselected = !!transfer?.isPreselected;

  const storeTransferBikeButtonTooltip = () => {
    if (!user?.user?.storeHasLegalRepresentative) {
      return 'La tienda no cuenta con representante legal';
    }
    if (transfer?.pilotIsSellingBike) {
      return 'Dueño está en proceso de transferencia';
    }
    if (transfer?.storeIsSellingBike) {
      return 'Bike en proceso de transferencia';
    }
    if (!isBikeDataCompleted || isMotorbikeDataCompleted) {
      return 'Completa los datos de la bike';
    }
    return '';
  };

  const handleClickCard = () => {
    if ((pathType === view.MARKETPLACE || pathType === view.LANDING) || (transfer?.isPublished && !isDisabled)) {
      const bikeString = transfer?.bike?.isBicycle
        ? `${encodeURIComponent(brandName)}-${encodeURIComponent(modelName)}-${encodeURIComponent(categoryName)}-${encodeURIComponent(sizeName)}-${encodeURIComponent(rimName)}`
        : `${encodeURIComponent(brandName)}-${encodeURIComponent(modelName)}-${encodeURIComponent(categoryName)}-${encodeURIComponent(transfer?.bike?.year)}`;
      let pathname = PATHS.MARKETPLACE_DETAIL.replace(':transferId', transferId);
      pathname = pathname.replace(':bikeString', bikeString);
      pathname = pathname.replace(':vehicleType', transfer?.bike?.isBicycle ? 'bicicleta' : 'motocicleta');
      const state = { screenMode: 'read-mode' };
      handleRedirectWithState({ pathname, state });
    }
  };

  const handleClickEditButton = (event) => {
    event.stopPropagation();
    goToEditPage({ transferId });
  };

  const handleClickGoToWorkShop = (event) => {
    event.stopPropagation();
    goToWorkshop({ bikeId });
  };

  const handleClickGoToInsurance = (event) => {
    event.stopPropagation();
    actions.clearSingleObject('bike');
    goToInsurance({ transferId });
  };
  const handleClickGoToTransfer = (event) => {
    event.stopPropagation();
    goToTransfer({ transferId });
  };

  // pending bikes methods
  const handleClickCancelButton = (event) => {
    event.stopPropagation();
    const { target } = event;
    const { parentElement } = target;

    const type = parentElement.getAttribute('data-type');
    const message = parentElement.getAttribute('data-message');

    onChange({ idTransferDelete: transferId, type, message });
    openAlertDelete();
  };

  const handleClickGoToBankDataInfo = (event) => {
    event.stopPropagation();
    goToBankDataInfo({ transferId });
  };

  const handleClickShowStatus = (event) => {
    event.stopPropagation();
    handleToggleModalPaymentStatus({ transferId });
  };

  const handleClickAcceptButton = (event) => {
    event.stopPropagation();
    goToAcceptTransfer({ transferId });
  };

  const handleClickCopyEmailButton = (event) => {
    event.stopPropagation();
    handleToggleSetSellerEmailDialog({
      open: true,
      bikeId,
      transferId,
    });
  };

  const handleClickWhatsAppButton = (event) => {
    event.stopPropagation();
    const { phone } = transfer?.buyer;

    if (!phone) return;

    const replacedBuyerPhone = phone.replace(/[+]/, '');

    const customText = 'Hola, vi tu Bike publicada en BIKFY, ¿sigue disponible?';

    window.open(`https://wa.me/${replacedBuyerPhone}?text=${customText}`, '_blank');
  };

  const handleClickPublishBikeButton = (event) => {
    event.stopPropagation();

    const isPublished = transfer?.isPublished;

    const toReplace = isPublished ? transferId : `${transferId}?new-publication=true`;
    const bikeString = transfer?.bike?.isBicycle
      ? `${encodeURIComponent(brandName)}-${encodeURIComponent(modelName)}-${encodeURIComponent(categoryName)}-${encodeURIComponent(sizeName)}-${encodeURIComponent(rimName)}`
      : `${encodeURIComponent(brandName)}-${encodeURIComponent(modelName)}-${encodeURIComponent(categoryName)}-${encodeURIComponent(transfer?.bike?.year)}`;

    let pathname = PATHS.MARKETPLACE_DETAIL.replace(':transferId', toReplace);
    pathname = pathname.replace(':bikeString', bikeString);
    pathname = pathname.replace(':vehicleType', transfer?.bike?.isBicycle ? 'bicicleta' : 'motocicleta');

    handleRedirect(pathname);
  };

  const handleClickRequestAccessButton = (event) => {
    event.preventDefault();
    requestAccess({ transferId });
  };
  const handleClickRememberToRegisterButton = () => {
    actions.rememberRegisterBike({ transferId });
  };

  const handleClickReportBikeButton = (event) => {
    event.stopPropagation();
    const bikfyPhone = '56956365938';
    const customText = `Hola, tenemos información de la Bike marca *${brandName}* modelo *${modelName}* con código de serie *${bikeSerial}* denunciada por robo.`;

    window.open(`https://wa.me/${bikfyPhone}?text=${customText}`, '_blank');
  };
  const handleClickRememberAccessForBike = () => {
    actions.rememberAccessForBike({ transferId });
  };
  const doNothing = (e) => e.stopPropagation();

  // TODO: change to component
  const stateButton = (
    <Button
      className="state-button"
      color="primary"
      onClick={handleClickShowStatus}
      variant="contained"
    >
      Estado
    </Button>
  );
  // disabled for production
  const insuranceDisableHandler = (
    !user
    || isDisabled
    || !isBikeDataCompleted
    || !isBikeInsurable
    || isInsured
    || isPreselected
  );
  // console.log(!user, isDisabled, !isBikeDataCompleted, !isBikeInsurable, isInsured, isPreselected);

  const handleRemoveStoreAccessAction = () => {
    const { accessedId } = transfer;

    removeStoreAccess({ accessedId });
  };

  // TODO: change to component
  const optionButton = (
    <OptionButton
      transferId={transferId}
      brandId={brandId}
      isMozaicList={isMozaicList}
      bikeSerial={bikeSerial}
      isReportedBike={isReportedBike}
      isCompleteBikeData={isBikeDataCompleted}
      goToEditPage={goToEditPage}
      goToReport={goToReport}
      goToReportBikfy={goToReportBikfy}
      classes={classes}
      bikeMarket={bikeMarket}
      handleToggleReportPublishModal={handleToggleReportPublishModal}
      handleClickCopyEmailButton={handleClickCopyEmailButton}
      handleClickWhatsAppButton={handleClickWhatsAppButton}
      handlePublishButton={handleClickPublishBikeButton}
      handleWorkshopButton={handleClickGoToWorkShop}
      handleTransferButton={handleClickGoToTransfer}
      handleInsuranceButton={handleClickGoToInsurance}
      insuranceDisableHandler={insuranceDisableHandler}
      doNothing={doNothing}
      disabled={!user}
      bikeDisabled={isDisabled}
      transfer={transfer}
      handleRemoveStoreAccessAction={handleRemoveStoreAccessAction}
      motorbikeInformation={motorbikeInformation}
      isBicycle={transfer?.bike?.isBicycle}
    />
  );

  const infoTooltipInsuranceButton = () => {
    if (!isBikeDataCompleted) {
      return 'Completa los datos de la Bike';
    }
    if (!isBikeInsurable) {
      return 'No cumple las condiciones para asegurar esta Bike. Estas son; (i) antigüedad máxima de 6 meses desde su compra original, (ii) precio menor o igual a $5.000.000 y (iii) contar con la boleta o factura. Esperamos que prontamente podamos flexibilizar estos requisitos y abarcar todas las bicicletas.';
    }
    if (isInsured) {
      return 'Bike asegurada';
    }
    if (isPreselected) {
      return 'Bike preseleccionada, se espera respuesta de BCI';
    }
    return '';
  };

  const infoTooltipCancelTransferButton = () => {
    if (transfer?.paymentMade) {
      return 'Ya se ha efectuado el pago por la Bike';
    }
    if (transfer?.isStoreSellingBike) {
      return `Transferida por la tienda ${transfer?.storeName}`;
    }
    return '';
  };

  const iconEditButton = (
    <IconButton onClick={handleClickEditButton}>
      <EditIcon fontSize="small" />
    </IconButton>
  );

  const iconEditErrorButton = (
    <IconButton onClick={handleClickEditButton}>
      <Alert severity="error" style={{ padding: '0' }}>
        <EditIcon fontSize="small" />
      </Alert>
    </IconButton>
  );

  return (
    <Card
      className={`${classes.root} ${isMozaicList ? classes.minHeight430px : ''}`}
      // pointer | default
      style={isMozaicList ? { flexDirection: 'column', cursor: 'pointer' } : { cursor: 'pointer' }}
      onClick={handleClickCard}
    >
      <Grid container>
        <Grid item sm={isMozaicList ? 12 : 5} xs={12}>
          <BikeImage
            url={imgUrl}
            reported={isReportedBike}
            openModal={setOpenPhotosModal}
            isDisabled={isDisabled}
            postedByBikfyStore={transfer?.postedByBikfyStore}
          />
        </Grid>

        <Grid item sm={isMozaicList ? 12 : 7} xs={12} className={classes.details}>
          <CardContent className={classes.content}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <OptionsAvailable
                  iconClass={iconClass}
                  classes={classes}
                  iconsServiceClass={iconsServiceClass}
                  withBikfyPay={bikeMarket?.creditCard ?? false}
                  WithFeesCreditCard={bikeMarket?.creditCardWithFees ?? false}
                  withDelivery={bikeMarket?.withDelivery ?? false}
                  withInvoice={transfer?.withInvoice ?? false}
                  isInsured={(isInsured ?? true) && view.PILOT_HOME === pathType}
                />
              </Grid>
              {pathType === view.MARKETPLACE && (
                <>
                  {/* {!isMozaicList && (
                    <DatePublished
                      isMozaicList={isMozaicList}
                      classes={classes}
                      createdAt={bikeMarket?.createdAt}
                    />
                  )} */}
                </>
              )}
            </Grid>
            <Grid item className="bike-info-container">
              {/* <Typography variant={isMozaicList ? 'h6' : 'h4'}> */}
              <Typography
                itemProp="name"
                variant="h2"
                component="h2"
                style={{
                  fontWeight: '500',
                  fontSize: '1.25rem',
                  fontFamily: 'Mark Pro',
                  lineHeight: 1.6,
                }}
              >
                <Box className={`${classes.bikfyGreyColor} ${titleCardClass}`}>
                  {`${brandName} ${modelName}`}
                  {isPilotBikesPath && (
                    <>
                      {showValidBikes
                        && (isBikeDataCompleted ? iconEditButton : iconEditErrorButton)}
                    </>
                  )}
                  {isStoreBikesPath && (
                    <>{transfer?.accesses?.[0]?.status === 'done' && iconEditButton}</>
                  )}
                </Box>
              </Typography>
            </Grid>

            {(pathType === view.MARKETPLACE || pathType === view.LANDING) && (
              <>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography
                      display="inline"
                      className={`${classes.bikfyGreyColor} ${sizeYearClass}`}
                      style={
                        isXS
                          ? {
                            margin: '5px 0px',
                          }
                          : {}
                      }
                    >
                      {bikeCardDescription}
                      {/* Talla {validate(sizeName)} | Año {validate(bikeYear)}{' '}
                      {rimName ? `| Aro ${rimName}` : ''} */}
                    </Typography>
                    <Typography
                      variant={isMozaicList ? 'h6' : 'h4'}
                      className={`${priceCardClass}`}
                    >
                      <Box fontWeight="fontWeightBold" className={classes.bikfyColorFont}>
                        {formatNumberToCLP(Number(bikeMarket?.price))}
                      </Box>
                    </Typography>
                  </Box>
                  {avatar && !isMozaicList && (
                    <Box>
                      <img
                        src={avatar}
                        alt="avatar"
                        style={{ maxHeight: '40px', maxWidth: '40px' }}
                      />
                    </Box>
                  )}
                </Box>
              </>
            )}

            {pathType === view.STORE_HOME && (
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Typography>{bikeCardDescription}</Typography>
                  <Typography>
                    {`Codigo Serie ${transfer?.bike?.isBicycle ? '' : 'Chasis'}: ${
                      transfer?.bike?.isBicycle
                        ? validate(transfer?.bike?.serial)
                        : validate(motorbikeInformation?.chassisSerial)
                    }`}
                  </Typography>
                </Grid>

                <Grid item>
                  {transfer?.ownerName && (
                    <Typography>{`Dueño: ${validate(transfer?.ownerName)}`}</Typography>
                  )}
                  <Typography>{`R.U.T: ${validate(transfer?.ownerTaxNumber)}`}</Typography>
                  <Typography>{`Correo: ${validate(transfer?.email)}`}</Typography>
                </Grid>
              </Grid>
            )}

            {pathType === view.PILOT_HOME && (
              <Box className="bike-info-container">
                <Typography
                  display="inline"
                  className={`${classes.bikfyGreyColor} ${sizeYearClass}`}
                  style={isXS ? { margin: '5px 0px' } : {}}
                >
                  {
                    bikeCardDescription /* `Talla ${validate(sizeName)} | Año ${validate(bikeYear)} | Aro ${rimName}` */
                  }
                </Typography>
              </Box>
            )}
          </CardContent>

          <Grid
            container
            spacing={1}
            justifyContent={isXS ? 'center' : 'space-between'}
            className={classes.controls}
            alignItems="flex-start"
          >
            <Grid item style={{ flex: 1 }}>
              {pathType === view.MARKETPLACE && (
                <>
                  {isMozaicList ? (
                    <>
                      {/* {pathType === view.MARKETPLACE && (
                        <DatePublished
                          isMozaicList={isMozaicList}
                          classes={classes}
                          createdAt={bikeMarket?.createdAt}
                        />
                      )} */}
                    </>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        {bikeMarket?.showContact && (
                          <Grid item>
                            <Tooltip
                              title={
                                !user ? 'Para contactar debes iniciar sesión' : 'Enviar WhatsApp'
                              }
                              arrow
                            >
                              <span>
                                {btns.sendWhatsapp({
                                  callback: user ? handleClickWhatsAppButton : doNothing,
                                  disabled: !user,
                                })}
                              </span>
                            </Tooltip>
                          </Grid>
                        )}
                        <Grid item>
                          <Tooltip
                            title={!user ? 'Para contactar debes iniciar sesión' : 'Enviar Correo'}
                            arrow
                          >
                            <span>
                              {btns.sendEmail({
                                callback: user ? handleClickCopyEmailButton : doNothing,
                                disabled: !user,
                              })}
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}

              {pathType === view.PILOT_HOME && (
                <>
                  <Grid container spacing={2}>
                    {(isBikeInsurable || showValidBikes) && (
                      <>
                        {transfer?.bike?.isBicycle && (
                          <Tooltip title={infoTooltipInsuranceButton()} arrow>
                            <Grid item>
                              {btns.insurance({
                                callback: handleClickGoToInsurance,
                                className: `${classes.darkButton}`,
                                isXS,
                                disabled: insuranceDisableHandler,
                              })}
                            </Grid>
                          </Tooltip>
                        )}
                        <Tooltip
                          title={
                            isBikeDataCompleted || isMotorbikeDataCompleted
                              ? ''
                              : 'Completa los datos de la bike'
                          }
                          arrow
                        >
                          <Grid item>
                            {btns.publish({
                              callback: handleClickPublishBikeButton,
                              isPublished: transfer.isPublished,
                              isXS,
                              disabled:
                                !user
                                || isDisabled
                                || !isBikeDataCompleted
                                || isMotorbikeDataCompleted,
                            })}
                          </Grid>
                        </Tooltip>
                        <Tooltip
                          title={
                            isBikeDataCompleted || isMotorbikeDataCompleted
                              ? ''
                              : 'Completa los datos de la bike'
                          }
                          arrow
                        >
                          <Grid item>
                            {btns.transfer({
                              callback: handleClickGoToTransfer,
                              isXS,
                              disabled:
                                !user
                                || isDisabled
                                || !isBikeDataCompleted
                                || isMotorbikeDataCompleted,
                            })}
                          </Grid>
                        </Tooltip>
                      </>
                    )}

                    {showTransferedBikes && <>{isBikfyPaid && stateButton}</>}

                    {showPendingBikes && (
                      <Box
                        className="button-container"
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                        paddingRight={2}
                        paddingTop={2}
                      >
                        {currentUserIsSeller ? (
                          <>
                            <Grid item className={`${classes.marginRigth5}`}>
                              <Tooltip title={infoTooltipCancelTransferButton()} arrow>
                                <span>
                                  <Button
                                    disabled={transfer?.paymentMade || transfer?.isStoreSellingBike}
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleClickCancelButton}
                                    data-type="delete"
                                    data-message="cancelada"
                                    className="button"
                                  >
                                    Cancelar
                                  </Button>
                                </span>
                              </Tooltip>
                            </Grid>
                            {isBikfyPaid && (
                              <>
                                <Grid item className={`${classes.marginRigth5}`}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleClickGoToBankDataInfo}
                                    className="variant-color-one cut-text-button"
                                    style={{
                                      color: '#fff',
                                      backgroundColor: '#303e48',
                                    }}
                                  >
                                    <span
                                      style={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      Cuenta Bancaria
                                    </span>
                                  </Button>
                                </Grid>
                                <Grid item className={`${classes.marginRigth5}`}>
                                  {stateButton}
                                </Grid>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleClickAcceptButton}
                              disabled={transfer.paymentStatus === null && transfer.buyerStatus}
                              className={`button ${classes.marginRight20}`}
                            >
                              Aceptar
                            </Button>
                            <Tooltip title={infoTooltipCancelTransferButton()} arrow>
                              <span>
                                <Button
                                  disabled={transfer?.paymentMade}
                                  variant="contained"
                                  color="secondary"
                                  data-type="reject"
                                  data-message="rechazada"
                                  onClick={handleClickCancelButton}
                                  className="button"
                                >
                                  Rechazar
                                </Button>
                              </span>
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    )}
                  </Grid>
                </>
              )}

              {pathType === view.STORE_HOME && (
                <Grid
                  container
                  spacing={2}
                  style={{ justifyContent: 'flex-end', paddingRight: '10px' }}
                >
                  {[
                    STORE_BIKE_STATUS['1'],
                    STORE_BIKE_STATUS['2'],
                    STORE_BIKE_STATUS['4'],
                  ].includes(storeHomeSortBy.field) && (
                    <>
                      <Grid item>
                        {btns.seeReport({
                          callback: () => (transfer?.bike?.isBicycle
                            ? goToReportBikfy({ brandId, bikeSerial })
                            : goToReportBikfy({
                              brandId,
                              bikeSerial: motorbikeChassisSerial,
                            })),
                        })}
                      </Grid>
                      {STORE_BIKE_STATUS['1'] === storeHomeSortBy.field
                        && transfer.hasAccess === false && (
                          <Grid item>
                            <Button
                              color="primary"
                              variant="outlined"
                              disabled={transfer.pendingAccess}
                              onClick={handleClickRequestAccessButton}
                            >
                              SOLICITAR ACCESO
                            </Button>
                          </Grid>
                      )}
                      {STORE_BIKE_STATUS['2'] === storeHomeSortBy.field && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClickRememberToRegisterButton}
                          >
                            RECORDAR REGISTRO
                          </Button>
                        </Grid>
                      )}
                    </>
                  )}
                  {[STORE_BIKE_STATUS['3']].includes(storeHomeSortBy.field) && (
                    <>
                      <Tooltip title={storeTransferBikeButtonTooltip()} arrow>
                        <Grid item>
                          {btns.transfer({
                            callback: handleClickGoToTransfer,
                            isXS,
                            disabled:
                              !user
                              || isDisabled
                              || !isBikeDataCompleted
                              || isMotorbikeDataCompleted
                              || transfer?.pilotIsSellingBike
                              || transfer?.storeIsSellingBike
                              || !user?.user?.storeHasLegalRepresentative,
                          })}
                        </Grid>
                      </Tooltip>
                    </>
                  )}
                  {[STORE_BIKE_STATUS['9']].includes(storeHomeSortBy.field) && (
                    <>
                      <Grid item>
                        <Tooltip title={infoTooltipCancelTransferButton()} arrow>
                          <span>
                            <Button
                              disabled={transfer?.paymentMade || transfer?.isStoreSellingBike}
                              variant="contained"
                              color="secondary"
                              onClick={handleClickCancelButton}
                              data-type="delete"
                              data-message="cancelada"
                              className="button"
                            >
                              Cancelar
                            </Button>
                          </span>
                        </Tooltip>
                      </Grid>
                    </>
                  )}
                  {[STORE_BIKE_STATUS['3'], STORE_BIKE_STATUS['5']].includes(
                    storeHomeSortBy.field,
                  ) && (
                    <Grid item>
                      {btns.maintenance({
                        callback: handleClickGoToWorkShop,
                        className: `${classes.darkButton}`,
                        isXS,
                        disabled: !user || isDisabled,
                      })}
                    </Grid>
                  )}
                  {[
                    STORE_BIKE_STATUS['3'],
                    STORE_BIKE_STATUS['5'],
                    STORE_BIKE_STATUS['6'],
                  ].includes(storeHomeSortBy.field) && (
                    <Grid item>
                      {btns.publish({
                        callback: handleClickPublishBikeButton,
                        isPublished: transfer.isPublished,
                        isXS,
                        disabled: !user || isDisabled,
                      })}
                    </Grid>
                  )}
                  {STORE_BIKE_STATUS['4'] === storeHomeSortBy.field && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClickRememberAccessForBike}
                      >
                        RECORDAR ACCESO
                      </Button>
                    </Grid>
                  )}
                  {STORE_BIKE_STATUS['7'] === storeHomeSortBy.field && (
                    <Grid item>
                      {btns.seeReport({
                        callback: () => (transfer?.bike?.isBicycle
                          ? goToReportBikfy({ brandId, bikeSerial })
                          : goToReportBikfy({
                            brandId,
                            bikeSerial: motorbikeChassisSerial,
                          })),
                      })}
                    </Grid>
                  )}
                  {STORE_BIKE_STATUS['8'] === storeHomeSortBy.field && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<WhatsAppIcon />}
                        onClick={handleClickReportBikeButton}
                      >
                        REPORTAR
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            {(pathType === view.MARKETPLACE && isMozaicList && user) && (
              <Grid item>
                {bikeMarket?.showContact ? (
                  // <IconButton
                // color="primary"
                // onClick={handleClickWhatsAppButton}
                  // >
                  //   <WhatsAppIcon />
                  // </IconButton>
                  <Button
                    color="primary"
                    id="btn-send-whatsapp"
                    variant="outlined"
                    onClick={handleClickWhatsAppButton}
                  >
                    <WhatsAppIcon />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="outlined"
                    id="btn-send-mail"
                    onClick={handleClickCopyEmailButton}
                  >
                    <MailOutlineIcon />
                  </Button>
                  // <IconButton
                  //   color="primary"
                  //   onClick={handleClickCopyEmailButton}
                  // >
                  //   <MailOutlineIcon />
                  // </IconButton>
                )}
              </Grid>
            )}
            {((isPilotBikesPath && showValidBikes) || pathType === view.MARKETPLACE) && (
              <Grid item>{optionButton}</Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default composedComponent(GeneralCard, saga, {
  saga: 'sagaHome',
  middlewares: [withStyles(styles)],
  states: [
    'app.objects',
    'app.objects.transfers',
    'app.order',
    'app.user',
    'home',
    'home.pathType',
    'home.sortBy',
    'home.storeHomeSortBy',
  ],
  actions: [homeActions, appActions],
});
