import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'store/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;

// Global constants for saga
export const STORE = {
  REGISTER_BIKE: `${PATH}REGISTER_BIKE`,
  REGISTER_BIKE_SUCCESS: `${PATH}REGISTER_BIKE_SUCCESS`,
  REGISTER_BIKE_ERROR: `${PATH}REGISTER_BIKE_ERROR`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  RESET_BIKE_CONTROLS: `${PATH}RESET_BIKE_CONTROLS`,
  FETCH_BRANDS: `${PATH}FETCH_BRANDS`,
  FETCH_BRANDS_SUCCESS: `${PATH}FETCH_BRANDS_SUCCESS`,
  FETCH_MOTORBIKE_BRANDS: `${PATH}FETCH_MOTORBIKE_BRANDS`,
  FETCH_MOTORBIKE_BRANDS_SUCCESS: `${PATH}FETCH_MOTORBIKE_BRANDS_SUCCESS`,
  FETCH_VEHICLE_TYPES: `${PATH}FETCH_VEHICLE_TYPES`,
  FETCH_VEHICLE_TYPES_SUCCESS: `${PATH}FETCH_VEHICLE_TYPES_SUCCESS`,
};

// actions
export const storeActions = {
  registerBike: createAction(STORE.REGISTER_BIKE, 'payload'),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'payload'),
  resetControls: createAction(STORE.RESET_CONTROLS),
  fetchBrands: createAction(STORE.FETCH_BRANDS),
  fetchMotorbikeBrands: createAction(STORE.FETCH_MOTORBIKE_BRANDS),
  fetchVehicleTypes: createAction(STORE.FETCH_VEHICLE_TYPES),
  resetBikeControls: createAction(STORE.RESET_BIKE_CONTROLS),
};

const initialState = {
  formControls: {
    email: '',
    taxNumber: '',
    amount: '',
    invoiceNumber: '',
    serial: '',
    brandId: '',
    invoiceFile: '',
    vehicleTypeId: 1,
    chassisSerial: '',
    engineSerial: '',
    insurance: 0,
  },
  invalidControls: {},
  loading: false,
  error: false,
  successRegister: false,
  message: '',
};

const register = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.payload,
    };
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.payload,
    };
  },
  [STORE.RESET_CONTROLS](state, action) {
    state.formControls = {
      ...initialState.formControls,
    };
  },
  [STORE.RESET_BIKE_CONTROLS](state, action) {
    state.formControls.brandId = initialState.formControls.brandId;
  },
  [STORE.REGISTER_BIKE](state) {
    state.loading = true;
    state.error = false;
  },
  [STORE.FETCH_BRANDS](state) {
    state.loading = true;
  },
  [STORE.FETCH_MOTORBIKE_BRANDS](state) {
    state.loading = true;
  },
  [STORE.FETCH_VEHICLE_TYPES](state) {
    state.loading = true;
  },
  [STORE.REGISTER_BIKE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [STORE.FETCH_BRANDS_SUCCESS](state) {
    state.loading = false;
  },
  [STORE.FETCH_MOTORBIKE_BRANDS_SUCCESS](state) {
    state.loading = false;
  },
  [STORE.FETCH_VEHICLE_TYPES_SUCCESS](state) {
    state.loading = false;
  },
  [STORE.REGISTER_BIKE_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
});

export default register;
